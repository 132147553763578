<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="标题">
                                <el-input v-model="search.title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="简介">
                                <el-input v-model="search.abstract"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button icon="el-icon-plus"
                                   v-if="is_auth('wxmp.wxcontent.issave')"
                                   size="medium" type="primary" @click="to_infopage">添加图文
                        </el-button>

                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="title"
                        label="标题"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="cover"
                        label="封面"
                        width="180">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 22px; height: 22px; margin-right: 4px;"
                                :src="scope.row.cover"
                                fit="contain"
                                :preview-src-list="[scope.row.cover]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="abstract"
                        label="简介">
                </el-table-column>

                <el-table-column
                        prop="update_time"
                        label="修改时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="300">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('wxmp.wxcontent.issave') "
                                size="mini" @click="to_infopage(scope.row,scope.row.wxcontent_uuid)">编辑
                        </el-button>
                        <el-button
                                v-if="is_auth('wxmp.wxcontent.isdel')"
                                size="mini" type="danger" @click="delinfo(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '图文管理',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                title: '',
                abstract: '',
                state: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "wxmp.wxcontent.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加
        to_infopage(e, wxcontent_uuid = '') {
            if (wxcontent_uuid === '') {
                this.$router.push({path: '/mpwx/wxcontent/info'})
            } else {
                this.$router.push({path: '/mpwx/wxcontent/info', query: {wxcontent_uuid}})
            }
        },
        // 删除
        delinfo(info) {
            // 弹出二次确认
            this.$confirm('确认删除' + info.title + '吗？', '确认信息', {})
                    .then(() => {
                        this.isdel(info.wxcontent_uuid)
                    })
                    .catch(() => {
                    });
        },
        // 删除操作
        isdel(wxcontent_uuid) {
            let postdata = {
                api_name: "wxmp.wxcontent.isdel",
                token: this.Tool.get_l_cache('token'),
                wxcontent_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
